pre {
    white-space: normal;
}

.title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    padding: 0 100px;
}

.wrapper {
    margin: '0 24px';
    margin-top: 100px;
    font-family: 'Helvetica Neue';
  }