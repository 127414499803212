.sidebar {
  /* width: 400px; */
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  /* borderRight: `1px solid ${theme.palette.divider}`, */
  /* background: theme.palette.background.paper, */
  padding-top: 15px;
}

.sidebar::-webkit-scrollbar {
    width: 0;
  }