.pharmacyContainer {
    width: 700px;
    margin: 0 auto;
    padding-bottom: 50;
    padding-top: 30
}
.pharmacy-title {
    margin: 25px auto;
    text-align: center;
}

.pharmacy-block {
    width: 280px;
    margin-bottom: 25px;
}
.pharmacy-content-block {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.pharmacy-input-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px auto;
}
.date-input {
    border-left: none;
    border-top: none;
    border-right: none;
}
.pharmacy-date-block {
    display: flex;
    justify-content: space-between;
    width: 450px;
    gap: 2px;
}
.day {
    width: 30px;
}
.month {
    width: 120px;
}
.year {
    width: 30px;
}
.date-input-description {
    display: flex;
    justify-content: center;
    margin-bottom: 25px
}
.date-born-block {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.type-block {
    display: flex;
    justify-content: center;
}
.date-born {
    border-left: none;
    border-top: none;
    border-right: none;
    width: 100%;
}
.dotted-block {
    display: flex;
    flex-direction: column;
}
.dottedSmall {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px dotted black;
    width: 95%;
}
.dottedLong {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px dashed black;
    width: 95%;
}
.caption-block {
    display: flex;
    justify-content: space-between;
}
.caption-block-text {
    display: flex;
    flex-direction: column;
    width: 300px;
}
.caption-block-text-long {
    display: flex;
    flex-direction: column;
    width: 500px;
}
.valid-block {
    display: flex;
    justify-content: center;
    margin: 25px auto;
}
.fio-block-patient {

    margin: 20px auto;
}
.fio-block-doctor {
    margin: 20px auto;
}
.money-block {
    display: flex;
    gap: 120px;
    margin: 20px auto;
}
.form148BackContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px 0;
  font-family: 'Helvetica Neue', sans-serif;
}

.form148Back-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form148Back-content-block,
.form148Back-header-block {
  margin-top: 15px;
}

.form148Back-block {
  margin-bottom: 10px;
}

.form148Back-header-block {
  display: flex;
  flex-direction: column;
}

.form148Back-header-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form148Back-header-title {
  width: 150px;
}

.form148Back-header-input {
  display: flex;
  align-items: center;
}

.form148Back-input {
  width: 200px;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
}

.checkbox-wrong-answer {
  margin-left: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid blue;
  background-color: red;
}

