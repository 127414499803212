.MuiMenu-paper {
    left: 0;
    height: '350px';
}

.flex {
    display: flex;
    width: 500px;
    flex-wrap: wrap;
    flex-flow: row wrap;
    top: 30px;
    z-index: 3000;
    overflow: auto;
    margin: 20px;
    height: 150px;
    position: absolute;
    justify-content: space-between;
    padding: 10px;
    /* background:#ffffff; */
  }

  .flex1 {
    display: flex;
    width: 500px;
    height: 150px;
    /* background: #ffffff; */
    justify-content: center;
    flex-wrap: wrap;
  }

  .flex::-webkit-scrollbar-track {
    background: rgba(25, 118, 210, 0.3);
    border-radius: 10px;
  }

  .flex::-webkit-scrollbar-thumb {
    background-color: rgba(25, 118, 210, 0.5);
    border-radius: 20px;
  }

  .flex::-webkit-scrollbar {
    width: 7px;
  }
  
  .flex:after {
    content: '';
    display: table;
  }
  
  .item {
    padding: 5px;
    border-radius: 5px;
    background-color: #1976d2;
    margin: 0 0 20px;
    color: white;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .flex:after,
  .item {
    width: 150px;
    text-align: center;
    cursor: pointer;
  }

  .item1 {
    width: 300px;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background-color: #1976d2;
    margin: 0 0 20px;
    color: white;
    height: 35px;
  }

  .cds--btn--primary {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: 0;
    color: var(--cds-text-on-color, #ffffff);
  }