.img {
    width: 300px;
    object-fit: contain;
}

.slide {
    width: 400px;
    margin-left: 100;
    margin-top: 40;
}

@media screen and (max-width:1150px) {
    .img {
        width: 140px;
    }
    .slide {
        width: 250px;
        margin-left: 10;
    }
}